import Image from 'next/image'

import { css } from '@emotion/react'
import { useAuthentication } from '@motrix/authentication'
import { Button, ShortBrand } from '@motrix/core'
import { styled } from '@motrix/tokens'
import Toolbar from '@mui/material/Toolbar'

import { links } from './links'

const AppBar = () => {
  const { me, signOut } = useAuthentication()

  const currentUrl = window.location.href

  return (
    <StyledNavBar>
      <Toolbar variant="dense" css={toolbarStyles}>
        <ShortBrand css={{ marginRight: '20px' }} />
        {links.map((link) => {
          const isActive =
            currentUrl === link.href && link.regex.test(currentUrl)

          return (
            <StyledNavLinks key={link.href} href={link.href}>
              {link.label}
              <Border
                className={isActive ? 'active' : ''}
                css={{ marginTop: '7px' }}
              />
            </StyledNavLinks>
          )
        })}

        <UsernameStyles role="menuitem" aria-label="Nome do usuário">
          {me.givenName}
        </UsernameStyles>
        <Button
          role="menuitem"
          aria-label="Desconectar"
          variant={'text'}
          size="sm"
          leftIcon={
            <Image
              alt="logout"
              height={18}
              src="/icons/logout.svg"
              width={18}
            />
          }
          onClick={async () => {
            await signOut()
          }}
        >
          Sair
        </Button>
      </Toolbar>
    </StyledNavBar>
  )
}

const StyledNavBar = styled('header', {
  backgroundColor: '$neutral01',
  position: 'static',
})

const toolbarStyles = css`
  @media print {
    display: none;
  }
`

const StyledNavLinks = styled('a', {
  '&:hover': {
    color: '$primary06',
    cursor: 'pointer',
  },
  color: '$neutral09',
  fontFamily: '$default',
  fontSize: '$md',
  margin: '20px 20px 13px 20px',

  transition: 'all 0.3s ease-in-out',
})

const UsernameStyles = styled('p', {
  fontFamily: '$default',
  fontSize: '$md',
  marginInlineEnd: '40px',
  marginLeft: 'auto',
})

const Border = styled('div', {
  '&.active': {
    borderBottomColor: '$primary06',
  },
  borderBottomColor: 'transparent',
  borderBottomStyle: 'solid',
  borderBottomWidth: '$2',
  transition: 'all 0.3s ease-in-out',

  width: '$6',
})

export default AppBar
