import type { FC } from 'react'

import AppBar from '@components/AppBar/AppBar'

const Layout: FC = ({ children }) => (
  <>
    <AppBar />
    <div>{children}</div>
  </>
)

export default Layout
