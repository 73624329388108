import { opacity } from '@motrix/tokens'
import { keyframes } from '@stitches/react'

export const fadeInOverlay = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: opacity['3'] },
})

export const fadeAndScaleIn = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
})

export const scaleIn = keyframes({
  '0%': { transform: 'scale(0)' },
  '100%': { transform: 'scale(1)' },
})

export const slideAndFadeIn = keyframes({
  '0%': { opacity: 0, transform: 'translateY(100%)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

export const slideAndFadeOut = keyframes({
  '0%': { opacity: 1, transform: 'translateY(0)' },
  '100%': { opacity: 0, transform: 'translateY(100%)' },
})

export const slideAndFadeFromLeft = keyframes({
  '0%': { opacity: 0, transform: 'translateX(10%)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

export const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
})

export const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

export const fadeOut = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
})
