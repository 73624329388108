import { Box } from '@motrix/core'
import { styled } from '@motrix/tokens'

const StyledDebugCloseBox = styled(Box, {
  display: 'flex',
  variants: {
    position: {
      left: {
        flexDirection: 'row',
      },
      right: {
        flexDirection: 'row-reverse',
      },
    },
  },
})

export default StyledDebugCloseBox
