import { FC, useState } from 'react'

import { ArrowLeft, ArrowRight, Box, Button, Eye, EyeOff } from '@motrix/core'

import StyledDebugCloseBox from './DebugCloseBox.styles'
import { DebugPopover } from './DebugPopover'
import StyledBox from './DebugToolBar.styles'

export interface DebugValue {
  name: string
  value?: string | number
}

export interface DebugData {
  data: Array<DebugValue>
}

interface DebugToolBarProps {
  envs?: Array<DebugData>
  info?: Array<DebugData>
  position?: 'left' | 'right'
  showToolbar: boolean
}

export const DebugToolBar: FC<DebugToolBarProps> = ({
  envs = [],
  info = [],
  position = 'left',
  showToolbar,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [newPosition, setNewPosition] = useState(position)

  if (!showToolbar) return null

  const handleOnClose = () => setIsOpen(false)

  const handleOnOpen = () => setIsOpen(true)

  const mergedValues = [...info, ...envs]

  const tooglePosition = () =>
    setNewPosition(newPosition === 'left' ? 'right' : 'left')

  const iconDirection = {
    left: <ArrowRight />,
    right: <ArrowLeft />,
  }

  const OpenDebug = () => {
    return (
      <>
        <Box
          style={{
            marginBottom: '15px',
          }}
        >
          {mergedValues?.map(({ data }, index) => (
            <DebugPopover key={index} data={data} />
          ))}
        </Box>

        <StyledDebugCloseBox position={newPosition}>
          <Button aria-label="close" size="sm" onClick={handleOnClose}>
            Close Debug
            <EyeOff />
          </Button>
          <Button
            size="sm"
            onClick={tooglePosition}
            style={{ margin: '0px 10px 0px 10px' }}
          >
            {iconDirection[newPosition]}
          </Button>
        </StyledDebugCloseBox>
      </>
    )
  }
  return (
    <StyledBox color="black" position={newPosition}>
      {!isOpen && (
        <Button
          aria-label="open"
          size="sm"
          onClick={handleOnOpen}
          disabled={isOpen}
        >
          Open Debug
          <Eye />
        </Button>
      )}

      {isOpen && <OpenDebug />}
    </StyledBox>
  )
}

export default DebugToolBar
