const sizes = {
  '1': '0.75rem',
  '10': '4.5rem',
  '2': '1rem',
  '3': '1.25rem',
  '4': '1.5rem',
  '5': '2rem',
  '6': '2.5rem',
  '7': '3rem',
  '8': '3.5rem',
  '9': '4rem',
  container: '1200px',
}

export { sizes }
