import {
  ComponentProps,
  ElementType,
  PropsWithChildren,
  ReactElement,
} from 'react'

import { ButtonContainer } from './Button.styles'

export interface ButtonProps extends ComponentProps<typeof ButtonContainer> {
  as?: ElementType
  label?: string
  leftIcon?: ReactElement
  rightIcon?: ReactElement
  size?: 'sm' | 'md' | 'lg'
}

export const Button = ({
  label,
  leftIcon,
  rightIcon,
  size,
  ...props
}: PropsWithChildren<ButtonProps>) => {
  return (
    <ButtonContainer {...props} size={size}>
      {leftIcon} {label || props.children} {rightIcon}
    </ButtonContainer>
  )
}
