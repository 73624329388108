import { useEffect, useState } from 'react'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'
import { RecoilRoot } from 'recoil'

import { SnackbarProvider } from '@contexts/SnackbarContext'
import theme from '@contexts/theme'
import { AuthenticationProvider } from '@motrix/authentication'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Drawers from '@ui/Drawers'
import Layout from '@ui/Layout'
import MaintenancePage from '@views/MaintenancePage/MaintenancePage'

import DebugToolBarProvider from '../contexts/DebugContext'

import '../styles/globals.css'

const queryClient = new QueryClient()

const App = ({ Component, pageProps }: AppProps) => {
  const isMaintenance = process.env.MAINTENANCE_MODE === '1'

  useEffect(() => {
    const hojarId: number = process.env.HOTJAR_ID
    if (
      hojarId &&
      canInitializeAnalytics(window.location.host) &&
      !isMaintenance
    )
      hotjar.initialize(hojarId, 6)
    const tagManagerArgs = {
      gtmId: 'GTM-WWX5R5GJ',
    }

    TagManager.initialize(tagManagerArgs)
  }, [isMaintenance])

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      {isMaintenance ? (
        <MaintenancePage />
      ) : (
        <>
          <GoogleAnalyticsScript />
          <AuthenticationProvider>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={theme}>
                <SnackbarProvider>
                  <Layout>
                    <RecoilRoot>
                      <Drawers />
                      <DebugToolBarProvider>
                        <Component {...pageProps} />
                      </DebugToolBarProvider>
                    </RecoilRoot>
                  </Layout>
                </SnackbarProvider>
              </ThemeProvider>
            </QueryClientProvider>
          </AuthenticationProvider>
        </>
      )}
    </>
  )
}

const canInitializeAnalytics = (host: string) => {
  const alowedDomainRegex =
    /^(curriculum\.s\.motrix\.global|curriculum\.motrix\.global)$/gi
  return alowedDomainRegex.test(host)
}

const GoogleAnalyticsScript = () => {
  const [canAddScript, setCanAddScript] = useState(false)

  useEffect(() => {
    setCanAddScript(canInitializeAnalytics(window.location.host))
  }, [])

  if (!canAddScript) return null

  return (
    <>
      <Script
        id="google-analytics-script"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS}`}
      />

      <Script id="google-analytics-initialize" strategy="lazyOnload">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.GOOGLE_ANALYTICS}', {
            page_path: window.location.pathname,
            });
        `}
      </Script>
    </>
  )
}

export default App
