import type { CSSProperties } from 'react'

import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Theme {
    colors: CustomColors
    typography: TypographyVariantsOptions
  }

  interface CustomColors {
    appBarBackground: CSSProperties['color']
    black: CSSProperties['color']
    blackDisabled: CSSProperties['color']
    blue: CSSProperties['color']
    divider: CSSProperties['color']
    inputBorderColor: CSSProperties['color']
    lightGray: CSSProperties['color']
    mainBackground: CSSProperties['color']
    outlinedBorder: CSSProperties['color']
    secondaryBackground: CSSProperties['color']
    sectionBackground: CSSProperties['color']
    sectionIndentBorder: CSSProperties['color']
    standards: CSSProperties['color']
    gray: CSSProperties['color']
  }

  interface ThemeOptions {
    colors: CustomColors
  }

  interface TypographyVariantsOptions {
    heading: {
      color: CSSProperties['color']
    }
    paragraph: {
      color: CSSProperties['color']
    }
  }
}

const theme = createTheme({
  colors: {
    appBarBackground: '#080206',
    black: '#000000',
    blackDisabled: 'rgba(0, 0, 0, 0.4)',
    blue: '#3f51b5',
    divider: 'rgba(0, 0, 0, 0.12)',
    gray: '#9E9E9E',
    inputBorderColor: '#230F3C',
    lightGray: '#9E9E9E',
    mainBackground: '#ffffff',
    outlinedBorder: 'rgba(0, 0, 0, 0.23)',
    secondaryBackground: '#EEEEEE',
    sectionBackground: '#E0E0E0',
    sectionIndentBorder: 'rgba(0, 0, 0, 0.26);',
    standards: '#6A1B9A',
  },
  palette: {
    error: {
      main: '#E31B0C',
    },
    info: {
      main: '#2196F3',
    },
    primary: {
      main: '#8B00D0',
    },
    success: {
      light: '#03C12E',
      main: '#048621',
    },
    warning: {
      main: '#ED6C02',
    },
  },
  typography: {
    heading: {
      color: '#212121',
    },
    paragraph: {
      color: '#616161',
    },
  },
})

export default theme
