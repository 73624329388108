export const contentPlanningPermissions = (actions: Set<string>) => {
  // Templates
  const CREATE_TEMPLATE = actions.has('create-template')
  const DELETE_TEMPLATE = actions.has('delete-template')
  const EDIT_TEMPLATE = actions.has('edit-template')
  const VIEW_ALL_TEMPLATE = actions.has('view-all-template')
  const VIEW_ANY_TEMPLATE = actions.has('view-any-template')

  // Contents
  const ASSIGN_TO_STEP = actions.has('assign-to-step')
  const CREATE_CONTENT = actions.has('create-content')
  const DELETE_CONTENT = actions.has('delete-content')
  const EDIT_ASSIGNMENT = actions.has('edit-assignment')
  const EDIT_CONTENT = actions.has('edit-content')
  const FORWARD_TO_ANY_STEP = actions.has('forward-content')
  const FORWARD_TO_NEXT_STEP = actions.has('forward-to-next-step')
  const RETURN_TO_ANY_STEP = actions.has('return-to-any-step')
  const RETURN_TO_PREVIOUS_STEP = actions.has('return-to-previous-step')
  const VIEW_ALL_CONTENT = actions.has('view-all-content')
  const VIEW_ANY_CONTENT = actions.has('view-any-content')
  const VIEW_ASSIGNMENT = actions.has('view-assignment')
  const VIEW_ASSIGNMENTS = actions.has('view-assignments')
  const VIEW_ASSIGNMENT_FIELDS = actions.has('view-assignment-fields')
  const VIEW_HISTORY = actions.has('view-history')
  const VIEW_PRINTED_VERSION = actions.has('view-printed-version')
  const VIEW_WORKFLOW = actions.has('view-workflow')

  return {
    ASSIGN_TO_STEP,
    CREATE_CONTENT,
    CREATE_TEMPLATE,
    DELETE_CONTENT,
    DELETE_TEMPLATE,
    EDIT_ASSIGNMENT,
    EDIT_CONTENT,
    EDIT_TEMPLATE,
    FORWARD_TO_ANY_STEP,
    FORWARD_TO_NEXT_STEP,
    RETURN_TO_ANY_STEP,
    RETURN_TO_PREVIOUS_STEP,
    VIEW_ALL_CONTENT,
    VIEW_ALL_TEMPLATE,
    VIEW_ANY_CONTENT,
    VIEW_ANY_TEMPLATE,
    VIEW_ASSIGNMENT,
    VIEW_ASSIGNMENTS,
    VIEW_ASSIGNMENT_FIELDS,
    VIEW_HISTORY,
    VIEW_PRINTED_VERSION,
    VIEW_WORKFLOW,
  } as const
}
