export const links = [
  {
    href: 'https://curriculum.motrix.global',
    label: 'Currículos',
    regex: /curriculum(.d|.s)?./,
  },
  // {
  //   regex: /dashboard(.d|.s)?./,
  //   label: 'Dashboard',
  //   href: 'https://dashboard.motrix.global',
  // },
]
