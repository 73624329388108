import { Box, Button, Copy, DebugValue, Flex, Paragraph } from '@motrix/core'

interface DebugPopoverProps {
  data: Array<DebugValue>
}

export const DebugPopover = ({ data }: DebugPopoverProps) => {
  const handleOnCopy = (text?: string | number) => {
    if (!text) return
    navigator.clipboard.writeText(text.toString())
  }

  return (
    <Flex>
      {data.map((datum, index) => (
        <Box key={`env${index}`} style={{ padding: '3px', width: '220px' }}>
          <Button
            variant="text"
            size="sm"
            onClick={() => handleOnCopy(datum.value)}
          >
            {datum.name}
            <Copy />
          </Button>

          <Paragraph
            color="neutral06"
            style={{
              maxWidth: '20ch',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            onClick={() => handleOnCopy(datum.value)}
          >
            {datum.value}
          </Paragraph>
        </Box>
      ))}
    </Flex>
  )
}
