import apiAxios from '@utils/axios'

import { Me } from '../../types/me.type'

const getMe = async (): Promise<Me | null> => {
  const result = await apiAxios.get(`/me`)

  if (result.status !== 200) return null

  return result.data as unknown as Me
}

const WorkspaceService = { getMe }

export default WorkspaceService
