import { createStitches, defaultThemeMap } from '@stitches/react'

import { borderWidths } from './border-widths'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { fontSizes } from './font-sizes'
import { fontWeights } from './font-weights'
import { fonts } from './fonts'
import { lineHeights } from './line-heights'
import { opacity } from './opacity'
import { radii } from './radii'
import { shadows } from './shadows'
import { sizes } from './sizes'
import { space } from './space'

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  media: breakpoints,
  theme: {
    borderWidths,
    colors,
    fontSizes,
    fontWeights,
    fonts,
    lineHeights,
    opacity,
    radii,
    shadows,
    sizes,
    space,
  },
  themeMap: {
    ...defaultThemeMap,
    bottom: 'space',
    height: 'sizes',
    left: 'space',
    lineHeight: 'space',
    opacity: 'opacity',
    outlineWidth: 'borderWidths',
    right: 'space',
    top: 'space',
    width: 'sizes',
  },
})
