import {
  ButtonBase,
  buttonSolidDisabled,
  focusVisible,
  iconDisabled,
  iconPrimary,
  iconWhite,
  textDisabled,
} from '@motrix/core'
import { styled } from '@motrix/tokens'

export const ButtonContainer = styled('button', ButtonBase, {
  border: 'solid transparent',
  borderWidth: '$2',

  defaultVariants: {
    size: 'md',
    variant: 'solid',
  },

  variants: {
    size: {
      lg: {
        padding: '$4 $6',
      },

      md: {
        padding: '$2 $4',
      },

      sm: {
        fontSize: '$sm',
        padding: '$1 $4',
      },
    },

    variant: {
      outlined: {
        '&:disabled': {
          borderColor: '$neutral04',
          color: '$neutral04',
          cursor: 'not-allowed',
          ...iconDisabled,
        },
        '&:not(:disabled):active': {
          background: '$primary01',
          borderColor: '$primary06',
          color: '$primary06',
          ...iconPrimary,
        },
        '&:not(:disabled):hover': {
          background: '$primary00',
          borderColor: '$primary06',
          color: '$neutral09',
          ...iconPrimary,
        },

        background: 'transparent',

        borderColor: '$neutral09',

        color: '$neutral09',

        ...focusVisible,
      },

      solid: {
        background: '$neutral09',
        color: '$neutral00',

        outline: 'solid transparent',
        outlineWidth: '$3',

        ...iconWhite,

        '&:active': {
          background: '$primary06',
          outline: 'none',
        },

        '&:disabled': {
          ...buttonSolidDisabled,
          ...iconDisabled,
        },

        '&:not(:disabled):hover': {
          outlineColor: '$neutral05',
        },

        ...focusVisible,
      },

      text: {
        '&:disabled': {
          cursor: 'not-allowed',
          ...textDisabled,
          ...iconDisabled,
        },
        '&:not(:disabled):active': {
          background: '$primary01',
          color: '$primary06',
          ...iconPrimary,
        },

        '&:not(:disabled):hover': {
          background: '$neutral05',
          color: '$neutral09',
        },

        background: 'transparent',

        color: '$neutral09',

        ...focusVisible,
      },
    },
  },
})
