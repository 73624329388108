const borderWidths = {
  1: '1px',
  2: '2px',
  3: '4px',
  4: '6px',
  5: '8px',
  none: 0,
}

export { borderWidths }
