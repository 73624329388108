import { forwardRef } from 'react'

import { SnackbarOptions } from '@domain/index'
import { css } from '@emotion/react'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Slide, { SlideProps } from '@mui/material/Slide'
import MuiSnackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="down" />
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export interface CustomSnackbarProps {
  options: SnackbarOptions
  open: boolean
  onClose: () => void
}

const CustomSnackbar = ({
  onClose,
  open,
  options: { message, severity, autoHide = true },
}: CustomSnackbarProps) => (
  <MuiSnackbar
    open={open}
    autoHideDuration={autoHide ? 6000 : null}
    onClose={onClose}
    TransitionComponent={SlideTransition}
    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
  >
    <Alert onClose={onClose} severity={severity} css={alertStyles}>
      <Typography role="contentinfo" data-testid="snackbar-message">
        {message}
      </Typography>
    </Alert>
  </MuiSnackbar>
)

const alertStyles = css`
  width: 100%;
  display: flex;
  align-items: center;
`

export default CustomSnackbar
