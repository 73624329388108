import { ReactNode } from 'react'
import { atom, useRecoilState } from 'recoil'

const drawerAtom = atom<{
  isOpen: boolean
  component: any
  params: any
}>({
  default: {
    component: null,
    isOpen: false,
    params: undefined,
  },
  key: 'Drawer',
})

const useDrawer = () => {
  const [drawer, setDrawer] = useRecoilState(drawerAtom)

  const openDrawer = (component: ReactNode, params: any) => {
    setDrawer({
      component,
      isOpen: true,
      params,
    })
  }

  const hideDrawer = () => {
    setDrawer({
      ...drawer,
      isOpen: false,
    })
  }

  return { drawer, hideDrawer, openDrawer }
}

export default useDrawer
