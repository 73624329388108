import { Box } from '@motrix/core'
import { styled } from '@motrix/tokens'
import { colors } from '@motrix/tokens'

const StyledBox = styled(Box, {
  '@media print': {
    display: 'none',
  },
  backdropFilter: 'blur(6px)',
  backgroundColor: colors.neutral00,
  borderRadius: '20px',
  bottom: '20px',
  boxShadow: '0px 0px 10px -7px rgba(0,0,0,0.75)',
  opacity: 0.8,
  padding: '10px',
  position: 'fixed',
  variants: {
    position: {
      left: {
        left: '20px',
      },
      right: {
        right: '20px',
      },
    },
  },
  width: 'auto',
  zIndex: 2,
})

export default StyledBox
