import { ComponentProps, ElementType } from 'react'

import { styled } from '@motrix/tokens'

export interface ParagraphProps extends ComponentProps<typeof Paragraph> {
  as?: ElementType
}

export const Paragraph = styled('p', {
  defaultVariants: {
    color: 'neutral09',
    fontWeight: '$regular',
    size: 'p5',
  },
  fontFamily: '$default',
  lineHeight: '$shorter',

  margin: 0,

  // TODO: Tighten allowed values to variants based on actual tokens mapping.
  variants: {
    color: {
      alert01: { color: '$alert01' },
      alert02: { color: '$alert02' },
      alert03: { color: '$alert03' },
      neutral00: { color: '$neutral00' },
      neutral01: { color: '$neutral01' },
      neutral02: { color: '$neutral02' },
      neutral03: { color: '$neutral03' },
      neutral04: { color: '$neutral04' },
      neutral05: { color: '$neutral05' },
      neutral06: { color: '$neutral06' },
      neutral07: { color: '$neutral07' },
      neutral08: { color: '$neutral08' },
      neutral09: { color: '$neutral09' },
      primary06: { color: '$primary06' },
    },

    fontWeight: {
      bold: { fontWeight: '$bold' },
      extra: { fontWeight: '$extra' },
      regular: { fontWeight: '$regular' },
      semibold: { fontWeight: '$semibold' },
    },

    size: {
      p1: { fontSize: '$6xl' },
      p2: { fontSize: '$4xl' },
      p3: { fontSize: '$2xl' },
      p4: { fontSize: '$xl' },
      p5: { fontSize: '$md' },
      p6: { fontSize: '$sm' },
      p7: { fontSize: '$xs' },
    },
  },
})
