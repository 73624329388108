import { Auth } from 'aws-amplify'

import {
  Authenticator,
  Theme,
  ThemeProvider as AmplifyThemeProvider,
  useTheme,
} from '@aws-amplify/ui-react'

import { Provider, useAuthentication } from './context'

import '@aws-amplify/ui-react/styles.css'
import './amplify'
import './stylesheet.css'

const components = {
  Header() {
    return (
      <div
        style={{
          background: '#FFF',
          paddingTop: '40px',
          textAlign: 'center',
        }}
      >
        <img src="/logo.svg" alt="Motrix" />
      </div>
    )
  },
  SignIn: {
    Header() {
      return (
        <p
          style={{
            fontFamily: 'Nunito Sans',
            fontSize: '20px',
            fontWeight: 'bold',
            marginTop: 0,
            paddingLeft: '40px',
            paddingTop: '40px',
          }}
        >
          Informe os campos abaixo para entrar:
        </p>
      )
    },
  },
}

const AuthenticationProvider = ({ children }) => {
  const { tokens } = useTheme()

  const theme: Theme = {
    name: 'Motrix Auth Theme',
    tokens: {
      colors: {
        brand: {
          primary: {
            '10': tokens.colors.neutral['10'],
            '100': tokens.colors.neutral['100'],
            '80': tokens.colors.neutral['100'],
            '90': tokens.colors.neutral['90'],
          },
        },
      },
    },
  }

  return (
    <AmplifyThemeProvider theme={theme}>
      <Authenticator components={components} hideSignUp={true}>
        <Provider>{children}</Provider>
      </Authenticator>
    </AmplifyThemeProvider>
  )
}

export { Auth, AuthenticationProvider, useAuthentication }
export type { PlatformsPermissions } from './permissions'
export { usePermissions } from './permissions'
