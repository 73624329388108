import { createContext, ReactNode, useCallback, useState } from 'react'

import { SnackbarOptions } from '@domain/index'
import CustomSnackbar from '@ui/Snackbar'

interface SnackbarContextData {
  closeSnackbar: (reason?: string | undefined) => void
  isSnackbarOpen: boolean
  openSnackbar: ({ autoHide, message, severity }: SnackbarOptions) => void
  options: SnackbarOptions
}

const SnackbarContext = createContext<SnackbarContextData>(
  {} as SnackbarContextData,
)

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)
  const [options, setOptions] = useState<SnackbarOptions>({} as SnackbarOptions)

  const closeSnackbar = useCallback((reason?: string) => {
    if (reason === 'clickaway') return
    setIsSnackbarOpen(false)
  }, [])

  const openSnackbar = useCallback(
    ({ autoHide = true, message, severity }: SnackbarOptions) => {
      setIsSnackbarOpen(true)
      setOptions({
        autoHide,
        message,
        severity,
      })
    },
    [],
  )

  return (
    <SnackbarContext.Provider
      value={{ closeSnackbar, isSnackbarOpen, openSnackbar, options }}
    >
      {children}
      <CustomSnackbar
        onClose={() => closeSnackbar()}
        open={isSnackbarOpen}
        options={options}
      />
    </SnackbarContext.Provider>
  )
}

export default SnackbarContext
