import { ComponentProps, ElementType } from 'react'

import { styled } from '@motrix/tokens'

export interface HeadingProps extends ComponentProps<typeof Heading> {
  as?: ElementType
}

export const Heading = styled('h2', {
  color: '$neutral09',
  defaultVariants: {
    size: 'h2',
  },
  fontFamily: '$default',
  fontWeight: '$bold',
  lineHeight: '$shorter',

  margin: 0,

  variants: {
    color: {
      neutral00: { color: '$neutral00' },
      neutral01: { color: '$neutral01' },
      neutral02: { color: '$neutral02' },
      neutral03: { color: '$neutral03' },
      neutral04: { color: '$neutral04' },
      neutral05: { color: '$neutral05' },
      neutral06: { color: '$neutral06' },
      neutral07: { color: '$neutral07' },
      neutral08: { color: '$neutral08' },
      neutral09: { color: '$neutral09' },
      primary06: { color: '$primary06' },
    },

    size: {
      h1: { fontSize: '$9xl' },
      h2: { fontSize: '$7xl' },
      h3: { fontSize: '$6xl' },
      h4: { fontSize: '$5xl' },
      h5: { fontSize: '$4xl' },
      h6: { fontSize: '$2xl' },
      h7: { fontSize: '$xl' },
      h8: { fontSize: '$md' },
      h9: { fontSize: '$sm' },
    },
  },
})
