import { ComponentProps } from 'react'

import { BrandContainer } from '@motrix/core'

export interface ShortBrandProps extends ComponentProps<typeof BrandContainer> {
  size?: 'sm' | 'md' | 'lg'
  color?: 'black' | 'white'
}

export const ShortBrand = ({
  size = 'sm',
  color = 'black',
  ...props
}: ShortBrandProps) => {
  return (
    <BrandContainer {...props} size={size}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 104 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 17.4364V70.1647C0 71.6568 1.20961 72.8664 2.70175 72.8664H15.5337C17.0361 72.8664 18.25 71.6406 18.2353 70.1383L17.7922 24.8351C17.7791 23.4964 19.3597 22.7766 20.3609 23.6654L49.5999 49.6191C50.6197 50.5243 52.1541 50.5277 53.1779 49.6271L82.3231 23.9881C83.3204 23.1107 84.8867 23.8189 84.8867 25.1473V70.1647C84.8867 71.6568 86.0963 72.8664 87.5884 72.8664H100.303C101.802 72.8664 103.015 71.6458 103.005 70.1466L102.652 17.4023C102.647 16.706 102.374 16.0384 101.889 15.5387L87.8158 1.03894C86.8108 0.00345904 85.1695 -0.0594539 84.0881 0.89606L53.1772 28.21C52.1542 29.114 50.6176 29.1128 49.5961 28.2072L18.9062 1.00234C17.8368 0.0543625 16.2141 0.103159 15.2036 1.11368L0.791325 15.526C0.284648 16.0327 0 16.7199 0 17.4364Z"
          fill={color}
        />
      </svg>
    </BrandContainer>
  )
}
