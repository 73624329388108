import { createContext, useContext } from 'react'
import type { PropsWithChildren } from 'react'

import { useAuthentication } from '@motrix/authentication'

import { contentPlanningPermissions } from '../content-planning'
import { taggingPermissions } from '../tagging'
import type {
  PlatformsPermissions,
  PlatformsPermissionsAsString,
} from '../types'

const PermissionsContext = createContext({} as PlatformsPermissions)

export const PermissionsProvider = ({ children }: PropsWithChildren) => {
  const { me } = useAuthentication()

  if (!me || !me.currentWorkspace) return null

  const allowedActions = new Set<PlatformsPermissionsAsString>(
    me.currentWorkspace.actions,
  )

  const permissions: PlatformsPermissions = {
    contentPlanning: contentPlanningPermissions(allowedActions),
    tagging: taggingPermissions(allowedActions),
  }

  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  )
}

export const usePermissions = () => {
  const permissions = useContext(PermissionsContext)

  if (!permissions)
    throw new Error('usePermissions must be used inside a PermissionsProvider.')

  return permissions
}
