import { CopySimple } from 'phosphor-react'

import { colors, sizes } from '@motrix/tokens'

import { PhosphorIconProps } from './interfaces'

export const Copy = ({
  color = colors.neutral09,
  size = '4',
  weight = 'regular',
}: PhosphorIconProps) => {
  return <CopySimple size={sizes[size]} weight={weight} color={color} />
}
