import { Suspense } from 'react'

import useDrawer from '@hooks/useDrawer'

const Drawers = () => {
  const { drawer, hideDrawer } = useDrawer()
  const { isOpen, params } = drawer
  const Component = drawer.component

  if (!Component) return null

  return (
    <Suspense fallback={<></>}>
      <Component isOpen={isOpen} onDismiss={hideDrawer} {...params} />
    </Suspense>
  )
}

export default Drawers
