import { styled } from '@motrix/tokens'

export const ButtonBase = styled('button', {
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '$6',
  boxSizing: 'border-box',
  cursor: 'pointer',
  display: 'flex',
  fontFamily: '$default',
  fontSize: '$md',
  fontWeight: '$bold',
  gap: '$2',
  justifyContent: 'center',
  outline: 'solid transparent',
  outlineWidth: '$4',
  padding: '0 $4',
  transition: 'all 0.2s ease-in-out',
})
