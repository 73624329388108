import { IconContainer } from '@motrix/core'
import { colors } from '@motrix/tokens'

import { IconProps } from './interfaces'

export const Logo = ({
  color = colors.neutral09,
  size = '4',
  ...props
}: IconProps) => {
  return (
    <IconContainer {...props} size={size}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 364 360"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M181.56 359.26C162.92 359.26 146.27 331.24 135.89 282.38L135.6 281L136.8 280.26C137.75 279.67 138.71 279.07 139.67 278.47C164.15 263.01 195.3 263.68 219.04 280.2C221.21 281.71 223.37 283.18 225.46 284.56L226.61 285.32L226.31 286.67C215.85 332.8 199.55 359.26 181.56 359.26ZM140.09 282.92C149.92 328.25 165.38 355.26 181.56 355.26C197.12 355.26 212.23 329.82 222.09 287.13C220.34 285.96 218.54 284.74 216.75 283.48C194.33 267.88 164.92 267.24 141.8 281.85C141.22 282.21 140.65 282.57 140.09 282.92Z"
          fill={color}
        />
        <path
          d="M184.68 95.21C173.03 95.21 161.39 91.6 151.6 84.42C146.91 80.98 142.22 77.71 137.67 74.69L136.52 73.93L136.82 72.58C147.26 26.46 163.56 0 181.56 0C200.2 0 216.85 28.02 227.23 76.88L227.52 78.26L226.32 79C222.82 81.16 219.22 83.48 215.63 85.88C206.29 92.11 195.48 95.21 184.68 95.21ZM141.03 72.13C145.27 74.97 149.62 78.01 153.97 81.2C171.49 94.04 195.38 94.58 213.41 82.55C216.64 80.4 219.86 78.31 223.02 76.34C213.19 31.01 197.73 4 181.56 4C165.99 4 150.88 29.44 141.03 72.13Z"
          fill={color}
        />
        <path
          d="M290.76 136.53L289.38 136.2C283.83 134.87 277.98 133.63 271.98 132.51C247.94 128.04 229.43 109.51 224.84 85.31C224.35 82.75 223.84 80.19 223.31 77.71L223.02 76.33L224.22 75.59C266.7 49.32 298.46 41.97 311.35 55.44C323.47 68.11 316.25 97.23 291.54 135.34L290.76 136.53ZM227.52 78.26C227.95 80.34 228.37 82.45 228.77 84.56C233.06 107.13 250.31 124.4 272.72 128.57C278.29 129.61 283.74 130.75 288.94 131.97C311.61 96.66 318.91 69.13 308.45 58.2C297.27 46.52 267.07 54.02 227.52 78.26Z"
          fill={color}
        />
        <path
          d="M70.28 310.41C62.26 310.41 56 308.23 51.76 303.81C39.64 291.14 46.86 262.02 71.57 223.91L72.34 222.72L73.72 223.05C78.11 224.1 82.7 225.1 87.37 226.02C113.45 231.15 133.73 251.45 139.01 277.75C139.27 279.03 139.53 280.29 139.79 281.54L140.08 282.92L138.88 283.66C110.36 301.31 86.67 310.41 70.28 310.41ZM74.17 227.28C51.5 262.59 44.2 290.12 54.66 301.05C65.84 312.74 96.05 305.24 135.6 281C135.43 280.19 135.26 279.37 135.1 278.55C130.13 253.85 111.1 234.78 86.61 229.96C82.36 229.12 78.19 228.22 74.17 227.28Z"
          fill={color}
        />
        <path
          d="M288.86 315.2C273.1 315.2 250.39 305.88 223.23 287.9L222.08 287.14L222.38 285.79C222.89 283.54 223.39 281.22 223.86 278.9C229.36 252 250.24 231.15 277.06 225.77C279.78 225.22 282.48 224.65 285.07 224.07L286.48 223.75L287.23 224.98C312.12 266.08 318.87 296.86 305.74 309.42C301.69 313.29 295.98 315.2 288.86 315.2ZM226.59 285.32C263.22 309.36 291.73 317.29 302.97 306.53C314.34 295.66 307.44 266.45 284.55 228.28C282.36 228.76 280.12 229.24 277.86 229.69C252.62 234.75 232.96 254.38 227.79 279.7C227.4 281.59 227 283.47 226.59 285.32Z"
          fill={color}
        />
        <path
          d="M76.62 135.51L75.87 134.28C50.99 93.18 44.24 62.4 57.37 49.84C70.37 37.4 100.44 45.25 139.87 71.36L141.02 72.12L140.71 73.47C139.63 78.24 138.62 83.24 137.69 88.34C133.66 110.5 116.1 128.04 93.98 131.98C88.5 132.96 83.13 134.04 78.02 135.19L76.62 135.51ZM74.29 48.02C68.24 48.02 63.45 49.57 60.14 52.73C48.77 63.6 55.67 92.8 78.56 130.98C83.3 129.93 88.25 128.95 93.29 128.05C113.77 124.4 130.04 108.16 133.76 87.63C134.61 82.95 135.53 78.35 136.51 73.94C110.65 56.96 88.83 48.02 74.29 48.02Z"
          fill={color}
        />
        <path
          d="M284.56 228.28L283.81 227.05C282.48 224.86 281.08 222.6 279.63 220.32C264.49 196.56 265.09 166.42 281.18 143.52C283.64 140.02 285.99 136.54 288.18 133.16L288.95 131.97L290.33 132.3C336.59 143.4 363.12 160.65 363.12 179.62C363.12 199.29 335 216.91 285.97 227.96L284.56 228.28ZM290.76 136.53C288.77 139.58 286.65 142.7 284.45 145.83C269.3 167.39 268.73 195.79 283 218.18C284.2 220.06 285.37 221.93 286.48 223.76C331.99 213.29 359.11 196.84 359.11 179.64C359.11 163.09 333.59 147.02 290.76 136.53Z"
          fill={color}
        />
        <path
          d="M74.16 227.28L72.78 226.95C26.53 215.85 0 198.6 0 179.63C0 159.96 28.12 142.34 77.15 131.29L78.55 130.97L79.3 132.2C80.12 133.55 80.95 134.91 81.81 136.28C97.67 161.65 97.01 193.84 80.14 218.31C78.34 220.92 76.59 223.54 74.93 226.09L74.16 227.28ZM76.63 135.51C31.12 145.98 4 162.43 4 179.63C4 196.17 29.52 212.24 72.35 222.73C73.8 220.52 75.3 218.28 76.85 216.04C92.81 192.89 93.43 162.42 78.42 138.4C77.81 137.43 77.22 136.47 76.63 135.51Z"
          fill={color}
        />
      </svg>
    </IconContainer>
  )
}
