import { ComponentProps } from 'react'

import { Flex, Heading, Logo, Paragraph } from '@motrix/core'

import { StyledSpinner, Wrapper } from './Loader.styles'

export interface LoaderProps extends ComponentProps<typeof Wrapper> {
  isLoading?: boolean
  title?: string
  subtitle?: string
}

export const Loader = ({
  isLoading = true,
  title,
  subtitle,
  ...props
}: LoaderProps) => {
  return (
    <Wrapper {...props}>
      <Flex align="center" direction="column" gap="5">
        <StyledSpinner data-loading={isLoading}>
          <Logo size="9" />
        </StyledSpinner>
        {(title || subtitle) && (
          <Flex align="center" direction="column" gap="1">
            {title && <Heading size="h8">{title}</Heading>}
            {subtitle && <Paragraph>{subtitle}</Paragraph>}
          </Flex>
        )}
      </Flex>
    </Wrapper>
  )
}

export interface LogoSpinnerProps extends ComponentProps<typeof StyledSpinner> {
  isLoading?: boolean
}

export const LogoSpinner = ({ isLoading, ...props }: LoaderProps) => {
  return (
    <StyledSpinner data-loading={isLoading} {...props}>
      <Logo size="9" />
    </StyledSpinner>
  )
}
