import { Eye as EyeIcon } from 'phosphor-react'

import { colors, sizes } from '@motrix/tokens'

import { PhosphorIconProps } from './interfaces'

export const Eye = ({
  color = colors.neutral09,
  size = '4',
  weight = 'regular',
}: PhosphorIconProps) => {
  return <EyeIcon size={sizes[size]} weight={weight} color={color} />
}
