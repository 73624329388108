const colors = {
  alert01: '#F02424',
  alert02: '#34C153',
  alert03: '#E7A13C',

  gradient00: '#2E6CF6',
  gradient01: '#7B61FF',

  link01: '#8D74F9',

  neutral00: '#FFFFFF',
  neutral01: '#FBFAF7',
  neutral02: '#F2F0EF',
  neutral03: '#E9E6E7',
  neutral04: '#D8D4D5',
  neutral05: '#B0ADAE',
  neutral06: '#6F6B70',
  neutral07: '#565057',
  neutral08: '#433E44',
  neutral09: '#262526',
  neutral10: '#191919',

  primary00: '#F4F7FE',
  primary01: '#EBF1FF',
  primary02: '#D5E2FD',
  primary03: '#ABC4FB',
  primary04: '#82A7FA',
  primary05: '#5889F8',
  primary06: '#2E6CF6',
  primary07: '#245DDC',

  purple01: '#EFEBFD',

  secondary01: '#623CEA',
  secondary02: '#F6C343',
  secondary03: '#D1625E',

  situation00: '#DDE1E5',
  situation01: '#F0F1F1',
  situation02: '#FBE7B4',
  situation03: '#FBF3E0',
  situation04: '#E7DAFF',
  situation05: '#F4EFFA',
  situation06: '#BFE9C6',
  situation07: '#E6F4E6',
  situation08: '#FFE4E4',
}

export { colors }
