export const textDisabled = {
  color: '$neutral05',
}

export const iconDisabled = {
  'svg > :is(circle, line, path, polyline, polygon, ellipse)': {
    stroke: '$neutral05',
  },

  'svg > rect:not(:nth-of-type(1))': {
    stroke: '$neutral05',
  },
}

export const iconWhite = {
  'svg > :is(circle, line, path, polyline, polygon, ellipse)': {
    stroke: '$neutral00',
  },

  'svg > rect:not(:nth-of-type(1))': {
    stroke: '$neutral00',
  },
}

export const iconPrimary = {
  'svg > :is(circle, line, path, polyline, polygon, ellipse)': {
    stroke: '$primary06',
  },

  'svg > rect:not(:nth-of-type(1))': {
    stroke: '$primary06',
  },
}

export const buttonSolidDisabled = {
  '& > p': {
    color: '$neutral05',
  },
  backgroundColor: '$neutral04',
  color: '$neutral05',

  cursor: 'not-allowed',
}

export const focusVisible = {
  '&:focus-visible': {
    outline: 'solid $primary06',
    outlineWidth: '$1',
  },
}
