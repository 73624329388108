import { styled } from '@motrix/tokens'

export const BrandContainer = styled('div', {
  defaultVariants: {
    size: 'md',
  },

  variants: {
    size: {
      lg: {
        height: '74px',
        width: 'auto',
      },
      md: {
        height: '34px',
        width: 'auto',
      },
      sm: {
        height: '17px',
        width: 'auto',
      },
    },
  },
})
