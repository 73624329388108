import { fadeInOverlay, spin } from '@motrix/core'
import { styled } from '@motrix/tokens'

export const Wrapper = styled('div', {
  alignItems: 'center',
  animation: `${fadeInOverlay} 0.3s ease-in-out`,
  backgroundColor: '$neutral00',
  direction: 'column',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  opacity: '$6',
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 6,
})

export const StyledSpinner = styled('div', {
  '&[data-loading="true"] ': {
    animation: `${spin} 7s linear infinite`,
  },
  height: 'fit-content',
  width: 'fit-content',
})
