const radii = {
  '1': '4px',
  '2': '8px',
  '3': '16px',
  '4': '20px',
  '5': '24px',
  '6': '50px',
  none: 0,
}

export { radii }
