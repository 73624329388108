import { styled } from '@motrix/tokens'

export const IconContainer = styled('div', {
  defaultVariants: {
    size: '4',
  },
  flexShrink: 0,

  variants: {
    size: {
      '1': {
        height: '$1',
        width: '$1',
      },
      '10': {
        height: '$10',
        width: '$10',
      },
      '2': {
        height: '$2',
        width: '$2',
      },
      '3': {
        height: '$3',
        width: '$3',
      },
      '4': {
        height: '$4',
        width: '$4',
      },
      '5': {
        height: '$5',
        width: '$5',
      },
      '6': {
        height: '$6',
        width: '$6',
      },
      '7': {
        height: '$7',
        width: '$7',
      },
      '8': {
        height: '$8',
        width: '$8',
      },
      '9': {
        height: '$9',
        width: '$9',
      },
    },
  },
})
