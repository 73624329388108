const opacity = {
  1: 0.1,
  2: 0.2,
  3: 0.4,
  4: 0.6,
  5: 0.8,
  6: 0.9,
  full: 1.0,
  none: 0.0,
}

export { opacity }
