import Axios from 'axios'

import { Auth } from '@motrix/authentication'

const baseURL = process.env.API_URL

const axios = Axios.create({
  baseURL,
  params: {},
})

axios.interceptors.request.use(async (request) => {
  const currentSession = await Auth.currentSession()

  request.headers!.Authorization = `Bearer ${currentSession
    .getAccessToken()
    .getJwtToken()}`

  return request
})

axios.interceptors.response.use(
  async (response) => {
    return response
  },
  async (error) => {
    if (error.response?.status === 401) {
      await Auth.signOut()
    }

    return Promise.reject(error)
  },
)

export default axios
