import Head from 'next/head'
import Image from 'next/image'

import { css } from '@emotion/react'
import { Heading, Paragraph } from '@motrix/core'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

const MaintenancePage = () => {
  return (
    <>
      <Head>
        <title>Motrix - Curriculum</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Box css={containerStyles}>
        <Box css={imageContainerStyles}>
          <Image src="/logo.svg" alt="Logo Motrix" height={40} width={172} />
          <Heading
            size="h7"
            css={{
              paddingTop: '15px',
            }}
          >
            Currículos
          </Heading>
        </Box>
        <Paper css={cardStyles}>
          <Heading
            size="h6"
            css={{
              padding: defaultPadding,
            }}
          >
            Manutenção
          </Heading>
          <Paragraph
            color="neutral06"
            css={{
              padding: defaultPadding,
            }}
          >
            O sistema está indisponível no momento.
            <br />
            Tente novamente mais tarde.
          </Paragraph>
        </Paper>
      </Box>
    </>
  )
}

const defaultPadding = '10px 0px 10px 0px'

const containerStyles = css`
  height: 100vh;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const imageContainerStyles = css`
  text-align: center;
  padding-top: 100px;
  margin-bottom: 52px;
`
const cardStyles = css`
  padding: 16px 42px;
  text-align: center;
  max-width: 480px;
  border-radius: 25px;
  box-shadow: 0px 2px 14px 1px rgba(86, 86, 86, 0.16);
`

export default MaintenancePage
